import React from 'react';
import { Layout } from '../components/Layout';
import { Heading } from '../components/Heading';
import { Text } from '../components/Text';

const Blog = () => (
  <Layout>
    <Heading>Blog</Heading>
    <Text>Hmnnnn. Nothing here yet?</Text>
  </Layout>
);

export default Blog;
